<template>
    <div class="potentialClient">
        <div class="head">
            <!--            导航-->
            <div ref="navBar">
                <rxNavBar title="潜客"  androidOrIOSFlag="true"></rxNavBar>
            </div>

            <!-- <van-nav-bar title="潜客" left-arrow @click-left="leftReturn" ref="navBar" fixed placeholder /> -->
            <!--        头部DIV-->
            <!--        客人转换-->
            <div class="potential">
                <van-tabs v-model="active" line-width="20" line-height="5" title-active-color="rgb(255,81,45)"
                          swipeable sticky :offset-top="offsetTop" @scroll="scroll" @change="changeTable" >
                    <van-tab title="私客">
                        <!--        搜索栏-->
                        <div class="headTitle">
                            <div class="search">
                                <van-search v-model="inputValue" placeholder="用户名/手机号/地址" @search="onPriSearch" />
                            </div>
                            <!--        审批下拉菜单-->
                            <!-- <van-dropdown-menu>
                                <van-dropdown-item v-model="value1" :options="option" @change="changeState" />
                            </van-dropdown-menu> -->
                            <div class="dromMenu">
                                <span :class="{'isActive':showPicker}" @click="showPicker =true">{{publicType.label}}</span>
                            </div>
                        </div>
                        <van-popup v-model="showPicker" round position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="option" @cancel="showPicker = false"
                                        @confirm="selectType" />
                        </van-popup>
                        <!--      私客的  循环列表开始===================================================-->
                        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh(0)"> -->
                        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                        <van-list v-model="loading" :finished="finished" offset="1200" :finished-text=" isEmpty ? '' : '已经到底了'" @load="init" v-if="checkAuthList(authButtonsList,'list')">
                            <div class="clientInfoList" v-for="item in orders" :key="item.id">
                                <!--            状态显示-->
                                <div v-if="item.resourceStatus=='1'" class="stateOne">{{ item.resourceStatusStr }}</div>
                                <div v-if="item.resourceStatus=='2'" class="stateTwo"> {{ item.resourceStatusStr }}</div>
                                <div v-if="item.resourceStatus=='3'" class="stateThree"> {{ item.resourceStatusStr }}</div>
                                <div v-if="item.resourceStatus=='4'" class="stateFour"> {{ item.resourceStatusStr }}</div>
                                <div v-if="item.resourceStatus=='5'" class="stateFive"> {{ item.resourceStatusStr }}</div>
                                <div v-if="item.resourceStatus=='6'" class="stateSix"> {{ item.resourceStatusStr }}</div>
                                <!--            客户名-->
                                <div class="clientDiv">
                                    <span class="clientName">{{item.userName}}</span>
                                    <!--                客户性别-->
                                    <span class="clientSex" v-if="item.gender==1">先生</span>
                                    <span class="clientSex" v-else>女士</span>
                                    <!--                电话图-->
                                    <img class="clientImg" src="../../assets/images/tel.png"
                                        @click.stop="showMobile(item)" alt="">
                                </div>

                                <!--            分割线1-->
                                <div class="ruleOne"></div>
                                <!--            备注-->
                                <div class="remarks" @click="jumpPotentialClientDetails(item,'0')">
                                    <!--                低格预算-->
                                    <p v-if="item.lowestPrice && item.highestPrice">
                                        价格预算：
                                        <span>{{item.lowestPrice}}元~</span>
                                        <span> {{item.highestPrice }}元</span>
                                    </p>
                                    <p v-else>
                                        价格预算:
                                        <span>暂无</span>
                                    </p>
                                    <p>
                                        来源：
                                        <span>{{ item.potentialUserSourceName }}</span>
                                    </p>
                                    <p>
                                        是否为BD客：
                                        <span>{{ item.isBD=='1'?'是':'否' }}</span>
                                    </p>
                                    <p>
                                        维护人：
                                        <span>{{ item.maintainStaffName }}</span>
                                    </p>
                                    <p>
                                        备注：
                                        <span>{{ item.remarks}}</span>
                                    </p>
                                <!--<div   class="OthertailInfo">
                                    <button span="1" v-if="(item.resourceStatus=='2'||item.resourceStatus=='3')&&checkAuthList(authButtonsList,'invalid')" @click.stop="effectivity(item)">有效</button>
                                </div> -->                                   
                                </div>
                                <div class="ruleTwo" v-if="(item.resourceStatus=='2'||item.resourceStatus=='3')&&checkAuthList(authButtonsList,'invalid')"></div>
                                <div   class="OthertailInfo">
                                     <span span="3" style="visibility:hidden;">暂无跟踪记录</span>
                                     <button  span="1" v-if="(item.resourceStatus=='2'||item.resourceStatus=='3')&&checkAuthList(authButtonsList,'invalid')" @click.stop="effectivity(item)">有效</button>
                                </div>                                
                                <!--           分割线2 -->
                                <div class="ruleTwo" v-if=" item.resourceStatus!='2'&&item.resourceStatus!='3'"></div>
                                <!--            跟踪信息-->
                                <div v-if="item.fuCount >= 1&& item.resourceStatus!='2'&&item.resourceStatus!='3'" class="tailInfo">
                                    <!--                进度条-->
                                    <div class="tailBar">
                                        <div class="bgPoint">
                                            <div class="topVertical"></div>
                                            <div class="outerCircle">
                                                <div class="insideCircle"></div>
                                            </div>
                                            <div class="bottomVertical"></div>
                                        </div>
                                    </div>
                                    <!--                跟踪信息-->
                                    <div class="tailDetail" v-if="item.resourceStatus!='2'&&item.resourceStatus!='3'">
                                        <div class="taila">
                                            <!--                    跟进图-->
                                            <div class="tailb">跟进</div>
                                            <!--                    跟进时间-->
                                            <span> {{item.fuaddTime}}</span>
                                        </div>
                                        <!--                    跟进内容 -->
                                        <span class="tailc"> {{ item.followUpDetail}}</span>
                                    </div>
                                    <!--                跟踪按钮-->
                                    <div v-if="item.resourceStatus!='2'&& item.resourceStatus!='3'">
                                        <button class="tailBtn" @click.stop="toAddFollowUp(item)">更多跟进</button>
                                    </div>
                                </div>
                                <!--            如果 statid==2显示下边的框-->
                                <div v-if="item.resourceStatus!='2' &&item.resourceStatus!='3'"  class="OthertailInfo">
                                    <span span="3" v-if="item.fuCount==0 ">暂无跟踪记录</span>
                                    <button span="1" v-if="item.fuCount==0&&checkAuthList(authButtonsList,'followUp')" @click.stop="toAddFollowUp(item)">跟进</button>
                                </div>
                            </div>
                        </van-list>
                            <common-empty v-if="isEmpty"></common-empty>
                        </van-pull-refresh>
                        <!-- </van-pull-refresh> -->
<!--                        <common-empty v-if="isEmptyPri"></common-empty>-->


                    </van-tab>
                    <van-tab title="公客">
                        <!--        搜索栏-->
                        <div class="headTitle">
                        <div class="search">
                            <van-search v-model="inputValue" placeholder="用户名/手机号/地址" @search="onPubSearch" />
                        </div>
                        <!--        审批下拉菜单-->
                        <!-- <van-dropdown-menu>
                            <van-dropdown-item v-model="value2" :options="option" @change="changeState" />
                        </van-dropdown-menu> -->

                        <div class="dromMenu">
                            <span :class="{'isActive':showPickerPlc}" @click="showPickerPlc =true">{{publicTypePlc.label}}</span>
                        </div>
                        </div>
                        <van-popup v-model="showPickerPlc" round position="bottom">
                            <van-picker show-toolbar value-key="label" :columns="optionPlc" @cancel="showPickerPlc = false"
                                @confirm="selectTypePlc" />
                        </van-popup>

                        <!--        公客的列表开始=========================================================-->
                        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh(1)"> -->
                        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                        <van-list v-model="loadingPlc" :finished="finishedPlc" :finished-text="isEmptyPlc ? '': '已经到底了'" @load="initPlc" v-if="checkAuthList(authButtonsList,'list')">
                            <div class="panelTwo" v-for="item in ordersPlc" :key="item.id"
                                @click="jumpPotentialClientDetails(item,'1')">
                                <div class="panelTwoTitle">
                                    <span class="titleOne">{{ item.acceptCount}} </span>
                                    <span class="titleTwo"> 个</span>
                                    <span class="titleThree">.</span>
                                    <span class="titleFour">{{ item.userName }}</span>
                                    <img src="../../assets/images/tel.png" @click.stop="showMobile(item)" />
                                </div>
                                <div class="ruleOne"></div>
                                <div class="panelTwoInfo">
                                    <span v-if="item.resourceStatus==1" class="infoStatePass">有效·</span>
                                    <span v-if="item.resourceStatus==2" class="infoStateInvalid">无效·</span>
                                    <span v-if="item.resourceStatus==3" class="infoStateSigned">已签·</span>
                                    <span v-if="item.resourceStatus==4" class="infoStateDing">已定·</span>
                                    <span class="infoDetail">
                                        {{item.businessCircleName}}
                                    </span>
                                    <span class="infoDetail">
                                        {{item.roomHouse?item.roomHouse:''}}
                                    </span>
                                </div>
                                <div class="panelTwoDetail">
                                    <span class="infoRental"> 租金预算：</span>
                                    <span class="infoRentalVal">
                                        <span>{{item.acceptPrice?item.acceptPrice+'元':'暂无'}}</span>
                                    </span>
                                </div>
                                <div   class="OthertailInfo">
                                    <button span="1" v-if="checkAuthList(authButtonsList,'switchToPrivateDisk')" @click.stop="publicDetailChangeToPrivate(item)">转为私客</button>
                                </div>                                
                                <!-- </div> -->
                            </div>
                        </van-list>
                        <common-empty v-if="isEmptyPlc"></common-empty>
                        </van-pull-refresh>
                        <!-- </van-pull-refresh> -->
                        <!--        公客的列表结束============================================================-->
                    </van-tab>

                </van-tabs>

            </div>
        </div>
        <!--        分割线3-->
        <!--<div class="ruleThree"></div>-->
        <!--        添加潜客按钮-->
        <div class="addClientBtnDiv" v-if="checkAuthList(authButtonsList,'add')">
            <button class="addClientBtn" @click="toAddPotential">+添加潜客</button>
        </div>
        <div class="addClientBtnDiv" v-if="checkAuthList(authButtonsList,'addbd')">
            <button class="addClientBtn" @click="toAddBD">+添加BD</button>
        </div>
        <!--拨打电话弹窗-->
        <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle"
            :mobileShow="isMobileShow" @hideDialog="hideDialog"> </dial-mobile>
<!--        <meta name="viewport" content="initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, width=device-width,viewport-fit=cover">-->
        <van-overlay :show="makesureShow" @click="makesureShow = false">
            <div class="wrapper" @click.stop>
                <div class="block" >
                    <div class="getPassword">
                        确认将{{rowData.userName}}转为{{stewardData.userName}}的私客吗？
                    </div>
                    <div class="LockPassWord">
                        <p class="password" @click="surePrivate()">确认 </p>
                    </div>
                </div>
                <div class="closeImg" @click="closePage">
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import {NavBar, Search, Tab, Tabs, Popup, Picker, Toast, PullRefresh, List, Dialog,Overlay, } from 'vant';
    import {
        potentialUserList,
        getStaffFuntionModelList,changeResourceStatus,updatePotentialResourceBelog
    } from '../../getData/getData'
    import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
    import rxNavBar from '../../components/rongxun/rx-navBar/navBar'
    import eventBus from '../../components/rongxun/rx-navBar/envbus'
    import {
        getStaffId,
        responseUtil,
        checkAuthList,
        globaluserId
    } from '../../libs/rongxunUtil';
    export default {
        components: {
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            [Toast.name]: Toast,
            [PullRefresh.name]: PullRefresh,
            [List.name]: List,
            dialMobile,
            rxNavBar,
            [Dialog.Component.name]: Dialog.Component,
            [Overlay.name]: Overlay,
        },
        data() {
            return {
                makesureShow:false,
                isLoading : false,
                //空状态图
                isEmpty : false,
                authButtonsList:[],
                refreshing: false,
                active: 0,
                offsetTop: '50',
                change: true,
                last: true,
                potClient: true,
                priClient: false,
                search: '',
                // 拨打电话弹窗
                isMobileShow: false,
                temporaryOwnerMobile: '',
                temporaryOwnerName: '',
                dialogTitle: '联系业主',
                publicType: {
                    value: '1',
                    label: '有效'
                },
                publicTypePlc : {
                    value: '1',
                    label: '有效'
                },
                option: [
                    {value: '0', label: '全部', defaultIndex: ''},
                    {value: '1', label: '有效'},
                    {value: '5', label: '0跟进'},
                    {value: '6', label: '0带看'},
                    {value: '7', label: '预约'},
                    {value: '4', label: '已定'},
                    {value: '3', label: '已签'},
                    {value: '2', label: '无效'},
                ],
                optionPlc: [
                    {value: '0', label: '全部', defaultIndex: ''},
                    {value: '1', label: '有效'},
                    {value: '4', label: '已定'},
                    {value: '3', label: '已签'},
                    {value: '2', label: '无效'},
                ],
                showPickerPlc : false,
                showPicker : false,
                orders: [],
                pageCount: '',
                totalPage: '',
                resourceBelog : '0',
                ordersPlc : [],
                pagePlc : 1,
                loadingPlc : false,
                finishedPlc : false,
                countPlc : 0,
                selected : true,
                ProductActivePlc : 1,
                ProductActive : 1,
                flagPlc : false,
                //分页
                firstLoadPri: '0',
                val : '',
                loading: false,
                finished: false,
                count:0,
                page:1,
                flag:false,
                pageCountPlc : '',
                inputValue : '',
                isEmptyPlc : false,
                staffList:[],    //员工集合
                rowData:{},
                stewardData:{}    //管家数据
            }
        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        created() {
            this.getStaffFuntionModelList()
            //判断私盘或公盘的tab
            if(localStorage.getItem('type')){
                this.active = Number(JSON.parse(localStorage.getItem('type')))
            }
            //判断私盘或公盘的下拉缓存
            if(!localStorage.getItem('currentLabel')){
                localStorage.setItem('currentLabel',JSON.stringify(this.$route.query.currentLabel))
            }
            if(localStorage.getItem('currentLabel') && localStorage.getItem('currentLabel') != undefined && localStorage.getItem('currentLabel') != "undefined"){
                if(this.active==0){
                    this.publicType.value = Number(JSON.parse(localStorage.getItem('currentLabel')))
                    for(let i =0;i<this.option.length;i++){
                        if(this.publicType.value==this.option[i].value){
                            this.publicType.label = this.option[i].label
                        }
                    }
                } else{
                    this.publicTypePlc.value = Number(JSON.parse(localStorage.getItem('currentLabelPublic')))
                    for(let i =0;i<this.optionPlc.length;i++){
                        if(this.publicTypePlc.value==this.optionPlc[i].value){
                            this.publicTypePlc.label = this.optionPlc[i].label
                        }
                    }
                }
            }
            //搜索缓存
            if(localStorage.getItem('inputValue')){
                this.inputValue = JSON.parse(localStorage.getItem('inputValue'))
            }
            // 公客默认有效
            if(localStorage.getItem('currentLabelPublic') == null){
                localStorage.setItem('currentLabelPublic','1')
            }
        },
        activated() {
            eventBus.$on(
                'referrer',
                function(data) {
                    console.log(data.signPersonInfo)
                    let that=this
                    that.publicToPrivate(data.signPersonInfo)                    
            }.bind(this))
        },
  //如果前往的页面不是推荐人，则摧毁缓存
  beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'referrer') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  },                
        methods: {
            publicToPrivate(data){
                this.stewardData=data
                this.makesureShow=true
            },
            closePage(){
                this.makesureShow=false
            },
            surePrivate(param){
                /*Dialog.confirm({
                    title: '友情提示',
                    message: '确定将此共客转为的私客吗？',
                }).then(() => {*/
                let that=this
                    let initData = {}
                    initData.user_id = globaluserId()
                    initData.id = that.rowData.id
                    initData.resourceBelog = '0'
                    initData.idList=that.rowData.prIdList
                    initData.staff_id=this.stewardData.id//param.id
                    console.log(initData)
                    updatePotentialResourceBelog(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            console.log(response)
                            responseUtil.alertMsg(that,'操作成功')
                            that.makesureShow=false
                            that.ordersPlc = [];
                            that.pagePlc = 1;
                            that.loadingPlc = false;
                            that.finishedPlc = false;
                            that.loadingPlc = true;   
                            that.isLoading = false;                         
                            that.initPlc()
                        })
                    })
                //})
            },
            //下拉刷新
            onRefresh() {
                if(this.active==0){
                    this.orders = [];
                    this.page = 1;
                    this.loading = false;
                    this.finished = false;
                    this.loading = true;
                    this.init();
                    this.isLoading = false;
                    responseUtil.alertMsg(this,"刷新成功")
                }else{
                    this.ordersPlc = [];
                    this.pagePlc = 1;
                    this.loadingPlc = false;
                    this.finishedPlc = false;
                    this.loadingPlc = true;
                    this.initPlc()
                    this.isLoading = false;
                    responseUtil.alertMsg(this,"刷新成功")
                }
                // console.log(this.active)
                // setTimeout(() => {
                //     responseUtil.alertMsg(this,'刷新成功')
                //     this.isLoading = false;
                // }, 1000);
            },
            getStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'potentialUser_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,
            scroll() {
                this.offsetTop = this.$refs.navBar.clientHeight
            },
            init( ) {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.staff_id = getStaffId()
                initData.currentPage = that.page
                initData.numberPage = 10
                initData.resourceType = 1
                initData.resourceBelog = 0
                if(that.publicType.value==5){
                    initData.resourceStatus = 1
                    initData.selectSearchType = 5
                }else if(that.publicType.value==6){
                    initData.resourceStatus = 1
                    initData.selectSearchType = 6
                }else if(that.publicType.value==7){
                    initData.resourceStatus = 1
                    initData.selectSearchType = 7
                }else{
                    initData.resourceStatus=that.publicType.value
                }
                initData.searchType = "0"
                initData.searchName = that.inputValue
                potentialUserList(initData).then(response => {
                    responseUtil.dealResponse(that, response, () => {
                        that.pageCount = response.data.data.pageCount
                        if(that.pageCount == 0&&that.page == 1){
                            that.isEmpty = true
                        }else{
                            that.isEmpty = false
                        }
                        if(that.pageCount!=0){
                            for(let i=0;i<response.data.data.data.length;i++){
                                that.orders.push(response.data.data.data[i])
                            }
                        }
                        // 加载状态结束
                        that.page++;
                        that.loading = false;
                        // that.firstLoadPri++;
                        // 数据全部加载完成
                        if (that.orders.length >= that.pageCount) {
                            that.finished = true;
                        }

                    })
                })
            },
            initPlc( ) {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.staff_id = getStaffId()
                initData.currentPage = that.pagePlc
                initData.numberPage = 10
                initData.resourceType = 1
                initData.resourceBelog = 1
                initData.resourceStatus=that.publicTypePlc.value
                initData.searchType = "0"
                initData.searchName = that.inputValue
                potentialUserList(initData).then(response => {
                    responseUtil.dealResponse(that, response, () => {
                        // console.log(response.data)
                        that.pageCountPlc = response.data.data.pageCount
                        if(that.pageCountPlc == 0&&that.pagePlc == 1){
                            that.isEmptyPlc = true
                        }else{
                            that.isEmptyPlc = false
                        }
                        if(that.pageCountPlc!=0){
                            for(let i=0;i<response.data.data.data.length;i++){
                                that.ordersPlc.push(response.data.data.data[i])
                            }
                        }
                        // 加载状态结束
                        that.pagePlc++;
                        that.loadingPlc = false;
                        // that.firstLoadPri++;
                        // 数据全部加载完成
                        if (that.ordersPlc.length >= that.pageCountPlc) {
                            that.finishedPlc = true;
                        }
                    })
                })
            },

            jumpPotentialClientDetails(item,type) {
                // console.log(item)
                item.resourceBelog = type
                this.$router.push({
                    name: 'potentialClientDetails',
                    query:item
                })
            },
            toAddFollowUp(item){
                let that = this
                that.$router.push({
                    name: 'addFollowUp',
                    query:{
                        id:item.prId,
                        followUpType : '0'
                    }
                })
            },
            changeTable(active) {
                this.active = active
                localStorage.setItem('type',JSON.stringify(this.active))
                this.inputValue = ''
                if(active==0){
                    this.publicType.value = 1
                    this.publicType.label = '有效'
                    this.orders = [];
                    this.ordersPlc = []
                    this.page = 1;
                    this.loading = false;
                    this.finished = false;
                    this.loading = true;
                    //选择私客时，将公客选择的类型存入缓存
                    localStorage.setItem('currentLabelPublic',JSON.stringify(this.publicTypePlc.value))
                    localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
                    //查看缓存中的保存的之前的私客选择的类型
                    this.publicType.value = Number(JSON.parse(localStorage.getItem('currentLabel')))
                    for(let i =0;i<this.option.length;i++){
                        if(this.publicType.value==this.option[i].value){
                            this.publicType.label = this.option[i].label
                        }
                    }
                    this.init()
                }else{
                    this.ordersPlc = [];
                    this.orders = [];
                    this.pagePlc = 1;
                    this.loadingPlc = false;
                    this.finishedPlc = false;
                    this.loadingPlc = true;
                    this.publicTypePlc.value = 1
                    this.publicTypePlc.label = '有效'
                    //选择公客的时候将私客选择的类型存入缓存
                    localStorage.setItem('currentLabel',JSON.stringify(this.publicType.value))
                    localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
                    //取出缓存中存放的公客的类型
                    this.publicTypePlc.value = Number(JSON.parse(localStorage.getItem('currentLabelPublic')))
                    for(let i =0;i<this.optionPlc.length;i++){
                        if(this.publicTypePlc.value==this.optionPlc[i].value){
                            this.publicTypePlc.label = this.optionPlc[i].label
                        }
                    }

                    this.initPlc()
                }
            },
            selectType(item){
                localStorage.setItem('currentLabel',JSON.stringify(item.value))
                this.orders = [];
                this.finished = true;
                this.page = 1;
                this.loading = false;
                this.finished = false;
                this.showPicker = false;
                // this.number= index;
                this.publicType.label = item.label
                this.publicType.value = item.value
                this.loading = true;
                this.init()
            },
            selectTypePlc(item){
                localStorage.setItem('currentLabelPublic',JSON.stringify( item.value))
                this.ordersPlc = [];
                this.finishedPlc = true;
                this.pagePlc  = 1;
                this.loadingPlc  = false;
                this.finishedPlc  = false;
                this.showPickerPlc  = false;
                this.publicTypePlc.label = item.label
                this.publicTypePlc.value = item.value
                this.loadingPlc = true;
                this.initPlc ()
            },
            onPriSearch() {
                this.orders = [];
                this.page = 1;
                this.loading = false;
                this.finished = false;
                this.loading = true;
                if(this.inputValue!=''){
                    localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
                }else{
                    localStorage.removeItem('inputValue')
                }
                this.init()
            },
            onPubSearch() {
                this.ordersPlc = [];
                this.pagePlc = 1;
                this.loadingPlc = false;
                this.finishedPlc = false;
                this.loadingPlc = true;
                if(this.inputValue!=''){
                    localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
                }else{
                    localStorage.removeItem('inputValue')
                }
                this.initPlc()
            },

            // 打电话
            showMobile(item) {
                let that = this;
                that.temporaryOwnerMobile = item.mobile;
                that.temporaryOwnerName = item.userName
                that.isMobileShow = !that.isMobileShow;
            },
            // 关闭电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
            toAddPotential(){
                this.$router.push({
                    name: 'addPotentialClient',
                    query:{
                        type:1
                    }
                });
            },
            toAddBD(){
                this.$router.push({
                    name: 'addPotentialClient',
                    query:{
                        type:2
                    }
                });
            },
            //有效
            effectivity(item){
                console.log(item)
                if (item.resourceStatus == '1') {
                    responseUtil.alertMsg(this, '已为有效状态！')
                    return
                }                
                Dialog.confirm({
                    title: '友情提示',
                    message: '确定转为有效吗？',
                }).then(() => {
                    var that = this
                    let initData = {}
                    initData.user_id = globaluserId()
                    initData.id = item.prId
                    initData.resourceStatus = '1'
                    changeResourceStatus(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            responseUtil.alertMsg(that,'操作成功')
                            that.orders=[]
                            that.finished = true;
                            that.page = 1;
                            that.loading = false;
                            that.finished = false;
                            that.showPicker = false;
                            that.loading = true;                            
                            that.init()
                        })
                    })
                })
                .catch(() => {
                });                
            },
            //转为私客
            publicDetailChangeToPrivate(item){
                console.log(item)
                this.rowData=item
                this.$router.push({
                    name: 'referrer',
                    query: {
                        type: 1,
                    },
                })               
            }, 
        },
    }
</script>

<style lang="less" scoped>
    * {
        padding: 0;
        margin: 0;
    }

    /*主体DIV*/
    .potentialClient {
        width: 100%;
        overflow: hidden;
        font-size: 14px;
        font-weight: bolder;
        font-family: PingFangSC-Semibold;
        /*padding-bottom: constant(safe-area-inset-bottom);*/
        /*padding-bottom: env(safe-area-inset-bottom);*/
    }

    .dromMenu {
        width: 345px;
        margin: 0 auto ;
        font-weight: 500;
        color: #FF5D3B;
        font-size: 13px;
        .isActive::after {
            top: 50%;
            transform-origin: top;
            transform: rotateZ(180deg);
        }

        span {
            position: relative;

            &::after {
                display: block;
                content: '';
                width: 0px;
                height: 0;
                border: 3px solid transparent;
                border-top-color: #000;
                position: absolute;
                right: -20px;
                top: ceil(50%-4px);
            }
        }
    }

    .su {
        position: absolute;
        left: 193px;
        color: #EFEFEF;
    }

    .headTitle{
        position: fixed;
        top:105px;
        z-index: 9;
        width: 100%;
        background-color:#f7f8fa;
    }

    /*点位*/
    .divOne {
        height: 106px;
    }

    /*切换潜客和私客的DIV*/
    // .clientChange {
    //     width: 100%;
    //     // height: 50px;
    //     background-color: #FFFFFF;
    //     /*align-items: center;*/
    //     font-size: 14px;
    // }

    /*循环列表*/
    .clientInfoList {
        width: 345px;
        margin: 0 auto 15px;
        background-color: #FFFFFF;
        border-radius: 8px;
        /*padding-bottom: 15px;*/
    }

    /*状态显示*/
    .stateOne,
    .stateTwo,
    .stateThree,
    .stateFour,
    .stateFour,
    .stateSix{
        width: 33px;
        height: 16px;
        line-height: 16px;
        border: 1px solid transparent;
        border-radius: 7px 0;
        font-size: 10px;
        color: #FFFFFF;
        text-align: center;
    }

    .stateOne {
        background-image: linear-gradient(#66CB94, #28D3B0);
    }

    .stateTwo {
        background: rgb(174,172,172);
        // background-image: linear-gradient(#FFC274, #FF5D3B);
    }
    .stateThree {
        background: rgb(24, 144, 255);
        // background-image: linear-gradient(#FFC274, #FF5D3B);
    }
    .stateFour {
        background-image: linear-gradient(#FFC274, #FF5D3B);
    }
    .stateSix,.stateFive{
        width: 43px;
        /*background-image: linear-gradient(#FFC274, #FF5D3B);*/
        background: linear-gradient(to right,#990066,#ff66ff);
    }

    /*  客户名  */
    .clientDiv {
        width: 90%;
        margin: 5px auto;
        display: flex;
        font-size: 15px;
        font-weight: bolder;

        .clientName {
            flex-grow: 0.2;
        }

        .clientSex {
            flex-grow: 10;
        }

        .clientImg {
            flex-grow: 0;
            width: 19.96px;
            height: 19.96px;
        }
    }

    /*分割线1*/
    .ruleOne {
        margin: 15px auto;
        background-color: rgba(239, 239, 239, 1);
        border: 1px solid rgba(239, 239, 239, 1);
    }

    /*    价格预算*/
    .remarks {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 15px;

        p {
            line-height: 25px;
        }

        span {
            color: #999999;
            font-size: 13px;
        }
    }

    /*    分割线*/
    .ruleTwo {
        background-color: #EFEFEF;
        margin: 0 auto 18px;
        border: 1px solid #EFEFEF;
    }

    .bgPoint {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 80%;
        .outerCircle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: 2px solid #FF5D3B;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            .insideCircle {
                border: 3px solid #FF5D3B;
                border-radius: 50%;
            }
        }

        .topVertical {
            height: 100%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to bottom, #FFE4B5, #FF5D3B);
        }

        .bottomVertical {
            height: 100%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to top, #FFE4B5, #FF5D3B);
        }
    }

    /*    跟踪信息*/
    .tailInfo {
        display: flex;
        justify-content: space-between;
        width: 92%;
        margin: 0 auto;

        img {
            height: 50px;
            width: 15px;
        }

        /*跟踪信息详情*/
        .tailDetail {
            width: 100%;
            margin-top: 5px;
            margin-right: 10px;
            /*border: 1px solid red;*/
            margin-left: 10px;
            padding-bottom: 10px;
            font-weight: 300;


            /*跟踪详情第一行DIV*/
            .taila {
                display: flex;

                /*跟进的DIV*/
                .tailb {
                    margin-right: 10px;
                    color: #FF5D3B;
                    background-color: #FFC0CB;
                    width: 35px;
                    text-align: center;
                    border-radius: 10px;
                    font-size: 10px;
                    line-height: 15px;
                }
            }

            /*跟踪描述问题的DIV*/
            .tailc {
                display: inline-block;
                margin-top: 6px;
                line-height: 20px;
                text-indent: 10px;
            }

            span {
                color: #999999;
                font-size: 12px;
            }
        }

        /*跟踪信息按钮*/
        .tailBtn {
            width: 80px;
            height: 30px;
            margin-top: 10px;
            /*flex-grow: 0.1;*/
            border: 1px solid transparent;
            background-image: linear-gradient(white, white),
                linear-gradient(to right, #FFC274, #FF5D3B);
            background-clip: padding-box, border-box;
            background-origin: border-box;
            border-radius: 8px;
            color: #FF5D3B;
            font-size: 12px;
            line-height: 20px;
        }
    }

    /*待待待的跟踪信息*/
    .OthertailInfo {
        display: flex;
        align-items: center;
        padding-bottom: 15px;

        span {
            font-size: 12px;
            width: 67%;
            margin-left: 20px;
            color: #999999;
        }

        button {
            width: 80px;
            height: 30px;
            border-radius: 8px;
            font-size: 12px;
            background-image: linear-gradient(to right, #FFC274, #FF5D3B);
            border: none;
            color: #FFFFFF;
        }
    }

    /*分割线三*/
    .ruleThree {
        width: 100%;
        border: 1px solid #EFEFEF;
        margin-top: 115px;
    }

    /*添加潜客的按钮*/
    .addClientBtnDiv {
        background-color: #fff;
        position: fixed;
        width: 100%;
        /*padding: 10px 15px;*/
        height: 70px;
        overflow: hidden;
        bottom: 0;
        /*padding-bottom: env(safe-area-inset-bottom);*/
    }

    .addClientBtn {
        width: 92%;
        height: 45px;
        border-radius: 8px;
        background-image: linear-gradient(to right, #7FCAFF, #3091FF);
        border: none;
        color: #FFFFFF;
        font-size: 16px;
        margin: 12.5px auto;
        display: block;
    }

    .panelTwo {
        width: 345px;
        margin: 0px auto 15px;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;

        .panelTwoTitle {
            height: 20px;
            display: flex;
            align-items: center;
            line-height: 20px;
            margin-left: 15px;
            padding-right: 15px;

            .titleOne {
                color: red;
                font-size: 25px;
                flex: 0.5;
            }

            .titleTwo {
                color: red;
                font-size: 14px;
                margin-top: 6px;
                flex: 0.5;
            }

            .titleThree {
                font-size: 25px;
                margin: -11px 3px 0;
                flex: 0.1;
            }

            .titleFour {
                font-weight: bold;
                font-size: 14px;
                margin-top: 6px;
                flex: 10;
            }

            img {
                width: 20px;
                height: 20px;
                flex: 0.5;
            }
        }

        /*分割线*/
        // .ruleOne {
        //     width: 100%;
        //     border: 0.5px solid #fafafa;
        //     margin: 15px auto;
        // }

        /*主体信息*/
        .panelTwoInfo {
            width: 92%;
            margin: 0 auto;
            font-size: 14px;
            /*display: flex;*/
            /*align-items: center;*/
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            .infoDotPass,
            .infoDotInvalid,
            .infoDotSigned {
                margin: -6px 3px 0;
                font-weight: bold;
            }

            .infoDotPass {
                color: #00B0B6;
            }

            .infoDotInvalid {
                color: #999;
            }

            .infoDotSigned {
                color: blue;
            }

            /*有效的字体显示状态*/
            .infoStatePass {
                color: rgb(47, 210, 172);
                margin-top: -1px;
            }

            /*无效的字体显示状态*/
            .infoStateInvalid {
                color: #999;
            }

            /*已签的字体显示状态 infoStatesigned*/
            .infoStateSigned {
                color: blue;
            }
            .infoStateDing{
                color: red;
            }

            .infoDetail {
                font-weight: bold;
                margin-right: 5px;
            }
        }

        /*主体信息详情*/
        .panelTwoDetail {
            width: 92%;
            margin: 10px auto;
            font-size: 14px;

            .infoRental {
                font-weight: bold;
            }

            .infoRentalVal {
                color: #999999;
                font-size: 13px;
            }
        }

    }
    .van-overlay{
            background-color: rgba(80,80,80,.7);;
    }  
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    } 
    .getPassword{
        background: url("../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        font-family: SimSun;
        margin-top:50px;
    } 
    /*关闭按钮*/
    .closeImg{
        margin-top: 50px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }                 
</style>